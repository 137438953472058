<template>
  <section
    slot="pdf-content"
    class="pdf-content"
  >
    <section class="pdf-item">
      <b-container fluid>
        <b-card no-body>
          <b-row class="m-3">
            <b-col cols="12">
              <b-row>
                <b-col
                  cols="6"
                  class="text-center"
                >
                  <b-row>Đơn vị:
                    <span class="font-weight-bolder">TRƯỜNG ĐẠI HỌC HOA LƯ</span>
                  </b-row>
                  <b-row>Mã QHNS:
                    <span class="font-weight-bolder">1040565</span>
                  </b-row>
                </b-col>

                <b-col
                  cols="6"
                  class="text-center"
                >
                  <span class="font-weight-bolder">Mẫu số: C40-BB</span><br>
                  <span class="">(Ban hành kèm theo TT số 107/2017/TT - BTC ngày 10/10/2017 của Bộ Tài Chính)</span>
                </b-col>
              </b-row>
              <b-row class="mt-0">
                <b-col cols="3" />
                <b-col
                  cols="6"
                  class="text-center px-2"
                >
                  <span class="font-weight-bolder">PHIẾU THU</span><br>
                  <em><span class="">{{ data.date }}</span><br></em>
                  Số: <span class="font-weight-bolder">96 (SN)</span>
                </b-col>
                <b-col cols="3" />
              </b-row>
              <b-row>
                <b-col cols="12">
                  <span>Tên tôi là: <span class="font-weight-bolder">{{ data.name }}</span></span><br>
                  <span>Địa chỉ: <span class="font-weight-bolder">{{ data.address }}</span></span><br>
                  <span>Nội dung: <span class="font-weight-bolder">{{ data.content }}</span></span><br>
                  <span>Số tiền: <span class="font-weight-bolder">{{ data.amount }}</span></span><br>
                  <span>Viết bằng chữ: <em class="font-weight-bolder">{{ data.amount_word }}</em></span><br>
                  <span>Kèm theo: Chứng từ kế toán.</span><br>
                  <b-row>
                    <b-col cols="4" class="text-center">
                      <span class="font-weight-bolder">Thủ trưởng đơn vị</span><br><br><br>
                      <span class="font-weight-bolder">Vũ Văn Trường</span><br>
                    </b-col>
                    <b-col cols="4" class="text-center">
                      <span class="font-weight-bolder">Kế toán trưởng</span><br><br><br>
                      <span class="font-weight-bolder">Bùi Thị Hải Yến</span><br>
                    </b-col>
                    <b-col cols="4" class="text-center">
                      <span class="font-weight-bolder">Người lập biểu</span><br><br><br>
                      <span class="font-weight-bolder"></span><br>
                    </b-col>
                  </b-row>
                  <span>Đã nhận đủ số tiền: - Bằng số:<span class="font-weight-bolder">{{ data.amount }}</span></span><br>
                  <span style="padding-left: 115px">- Bằng chữ: {{ data.amount_word }}</span><br>
                </b-col>
              </b-row>
              <b-row class="mt-0">
                <b-col cols="5">
                  <div class="text-center">
                    <span class="font-weight-bolder">NGƯỜI NỘP</span>
                  </div>
                </b-col>
                <b-col cols="7">
                  <div class="text-center">
                    <em>Ninh Bình, ngày {{ today.date }} tháng {{ today.month }} năm {{ today.year }}</em>
                    <p class="font-weight-bolder">
                      THỦ QUỸ
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </section>
  </section>
</template>

<script>
import {
  BCard, BCol, BContainer, BRow,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PdfContent',
  components: {
    BCard,
    BCol,
    BContainer,
    BRow,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        name: '',
        address: '',
        content: '',
        amount: '',
        amount_word: '',
        date: '',
      }),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    today() {
      const date = new Date()
      return {
        date: `0${date.getDate()}`.slice(-2),
        month: `0${date.getMonth() + 1}`.slice(-2),
        year: date.getFullYear(),
      }
    },
  },
}
</script>

<style lang="scss">
.pdf-content {
  width: 100%;
  font-family: "Times New Roman", Times, serif !important;
  font-size: 14px;
  color: #000000;
}
</style>
