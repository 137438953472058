<template>

    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
    >
      <validation-observer
        ref="historyFormRef"
      >
        <b-modal
          id="historyModal"
          size="xl"
          centered
          ok-only
          title="Diễn biến thu học phí"
          @show="onShow"
        >
          <div class="m-2">
            <b-container fluid>
            <b-row>
              <b-col cols="6">
                <b-form-group
                    label="Mã học viên"
                    label-for="code_student"
                >
                  <b-form-input
                      id="code_student"
                      v-model="currentHSReceipt.student_code"
                      name="code_student"
                      :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                    label="Họ và tên"
                    label-for="fullName"
                >
                  <b-form-input
                      id="fullName"
                      v-model="currentHSReceipt.full_name"
                      name="fullName"
                      :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                    label="Khóa học"
                    label-for="major_name"
                >
                  <b-form-input
                      id="major_name"
                      v-model="currentHSReceipt.major_name"
                      name="major_name"
                      :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                    label="Lớp"
                    label-for="class_name"
                >
                  <b-form-input
                      id="class_name"
                      v-model="currentHSReceipt.class_name"
                      name="class_name"
                      :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row style="margin-top: 10px">
              <b-col cols="12">
                <h3><span class="badge badge-primary">Các khoản chưa nộp</span></h3>
                <div>
                  <b-table
                      striped
                      hover
                      :items="revenue_cn_items"
                      :fields="revenue_cn_fields"
                  >
                    <template #cell(amount)="data">
                      {{
                        new Intl.NumberFormat("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        }).format(data.item.amount)
                      }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
            <b-row style="margin-top: 10px">
              <b-col cols="12">
                <h3><span class="badge badge-primary">Các khoản đã nộp</span></h3>
                <div>
                  <b-table
                      striped
                      hover
                      :items="revenue_dn_items"
                      :fields="revenue_dn_fields"
                  >
                    <template #cell(amount)="data">
                      {{
                        new Intl.NumberFormat("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        }).format(data.item.amount)
                      }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
            <b-row style="margin-top: 10px">
              <b-col cols="12">
                <h3><span class="badge badge-primary">Miễn giảm</span></h3>
                <div>
                  <b-table
                      striped
                      hover
                      :items="exemption_items"
                      :fields="exemption_fields"
                  >
                    <template #cell(amount)="data">
                      {{
                        new Intl.NumberFormat("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        }).format(data.item.amount)
                      }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
            </b-container>
          </div>
        </b-modal>
      </validation-observer>
    </b-overlay>
</template>

<script>
import {
  BCol,
  BContainer,
  BFormGroup, BFormInput, BModal, BOverlay, BRow, BTable,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'HistoryReceipt',
  components: {
    BContainer,
    BTable,
    BFormGroup,
    BFormInput,
    BModal,
    BOverlay,
    ValidationObserver,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    studentClassesId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      currentHSReceipt: {
        student_classes_id: null,
        student_code: null,
        student_id: null,
        last_name: null,
        first_name: null,
        class_name: null,
        major_name: null,
        full_name: null,
      },
      revenue_dn_fields: [
        { key: 'semester_name', label: 'Kỳ học' },
        { key: 'receipt_name', label: 'Loại khoản thu' },
        { key: 'receipt_code', label: 'Mã phiếu thu' },
        { key: 'receipt_date', label: 'Ngày nộp' },
        { key: 'receipt_nt', label: 'Người thu' },
        { key: 'amount', label: 'Số tiền' },
      ],
      revenue_dn_items: [],
      revenue_cn_fields: [
        { key: 'semester_name', label: 'Kỳ học' },
        { key: 'receipt_name', label: 'Loại khoản thu' },
        { key: 'amount', label: 'Số tiền' },
      ],
      revenue_cn_items: [],
      exemption_fields: [
        { key: 'semester_name', label: 'Kỳ học' },
        { key: 'revenue_name', label: 'Loại miễn giảm' },
        { key: 'exemption_name', label: 'Loại đối tượng' },
        { key: 'amount', label: 'Số tiền' },
      ],
      exemption_items: [],
    }
  },
  computed: {
    ...mapGetters({
      listHistoryStudentInfo: 'receipt/listHistoryStudentInfo',
      listHistoryRevenuesCN: 'receipt/listHistoryRevenuesCN',
      listHistoryRevenuesDN: 'receipt/listHistoryRevenuesDN',
      listHistoryExemptions: 'receipt/listHistoryExemptions',
    }),
  },
  methods: {
    ...mapActions({
      getHistoryReceipt: 'receipt/getHistoryReceipt',
    }),
    async onShow() {
      if (this.studentClassesId) {
        await this.getHistoryReceipt({ student_class_id: this.studentClassesId })
        this.currentHSReceipt = {
          ...this.currentHSReceipt,
          ...this.listHistoryStudentInfo[0],
        }
        this.revenue_dn_items = this.listHistoryRevenuesDN
        this.revenue_cn_items = this.listHistoryRevenuesCN
        this.exemption_items = this.listHistoryExemptions
      }
    },
  },
}
</script>
